/* Основной контейнер */
.game-container {
  display: flex;
  padding-top: 50px;
  font-family: "Manrope", sans-serif;
  background: linear-gradient(135deg, #0c0e27, #3a3136);
  max-height: 140vh;
  transition: margin-left 0.3s ease;
  padding-left: 100px;
  padding-right: 40px;

}

html, body {
  overflow-x: hidden;
}

.shift-right {
  margin-left: 280px;
}
.mobile-filters-button {
  display: none;
}
/* Область продуктов */
.products-section {
  flex-grow: 1; /* Чтобы секция продуктов занимала оставшееся пространство */
  overflow-y: auto; /* Добавляет вертикальную прокрутку */
  max-height: calc(140vh); /* Установите высоту секции, чтобы она не выходила за экран */
  padding-right: 15px; 
}
.products-section::-webkit-scrollbar {
  width: 0px; /* Устанавливаем ширину скроллбара в 0 */
}

/* Скрываем боковой скроллбар в Firefox */
.products-section {
  scrollbar-width: none; /* Отключаем отображение скроллбара */
}
.product-game-title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #ff1493;
}

.filters-and-sort {
  display: flex;
  justify-content: space-between; /* Распределяем элементы по краям */
  align-items: center; /* Выравниваем элементы по вертикали */
  margin-bottom: 20px; /* Отступ снизу */
}

.sort-options {
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Сортировка выравнивается по правой стороне */
}

.sort-options label {
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
}

.sort-options select {
  background-color: #282a36;
  color: #ffffff;
  padding: 8px;
  border: 1px solid #44475a;
  border-radius: 5px;
  cursor: pointer;
}

.sort-options select:focus {
  outline: none;
  border-color: #ff1493;
}

/* Сетка с товарами */
.products-game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  padding-right: 10px;
}

/* Стили для карточек товаров */
.cart-container {
  padding: 40px;
  background: #1f1b2d;
  color: white;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

.shift-right {
  margin-left: 280px;
}

.cart-title {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #ff1493;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0 8px 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cart-item:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px;
}

.cart-item-image {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-details p {
  margin: 5px 0;
}

.cart-item-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.remove-button {
  background: none;
  color: #ff0000;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.order-summary {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ffffff;
}

.order-summary-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.order-summary-total {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: bold;
}

.checkout-button {
  background: #ff1493;
  color: white;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 8px;
  margin-top: 20px;
  transition: background 0.3s ease;
  width: 100%;
  text-align: center;
}

.checkout-button:hover {
  background: #ff66b2;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
}

.cart-item-status {
  background: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
  margin-top: 5px;
}

.product-game-cart {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-game-cart:hover .product-cart_inside {
  transform: scale(1.05);
  box-shadow: 0 8px 16px;
}

.product-game-cart_inside {
  position: relative;
  z-index: 1;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.product-game-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  margin-bottom: 10px;
}

.product-game-image {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.product-game-info {
  text-align: left;
}

.product-game-name-category {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
}

.product-game-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-game-price-quality {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 7px;
}

.product-game-price {
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
}

.product-game-quality-tag {
  background-color: #28a745;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 5px;
}

.product-game-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy-game-now-button {
  background-color: #262626;
  color: #ffffff;
  padding: 7px 2px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.buy-game-now-button img {
  width: 20px;
  height: auto;
}

.buy-game-now-button:hover {
  background-color: #444444;
}
.filters {
  padding: 20px;
  border-radius: 15px;
  color: #f5f1f3;
  width: 250px;
  padding-right: 60px;
}

/* Фильтры */
.filter-section {
  margin-bottom: 20px;
}

.filter-header {
  font-size: 18px;
  font-weight: bold;
  color: #ff1493;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 5px;
}

.filter-content {
  padding: 10px 0;
}

/* Чекбоксы - теперь они будут располагаться вертикально */
.filter-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.filter-checkboxes label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}


input[type="checkbox"] {
  appearance: none;
  margin-right: 10px;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #2c243e;
  border: 1px solid #4e4761;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

input[type="checkbox"]:hover {
  background-color: #3b314e; /* При наведении */
}

/* Галочка появляется при checked */
input[type="checkbox"]:checked {
  background-color: #ff1493;
  border-color: #ff1493;
}

/* Добавляем галочку через label:after */
input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS41MTA4IDUuMDc1OTJDMTUuODc0MiA1LjM1Mjk4IDE1LjkzOTggNS44NjY0NyAxNS42NTczIDYuMjIyODJMOS4xNzgxMiAxNC4zOTU5QzkuMDMzODMgMTQuNTc3OSA4LjgxNzg3IDE0LjY5MTcgOC41ODM0NyAxNC43MDkyQzguMzQ5MDcgMTQuNzI2NyA4LjExODAzIDE0LjY0NjMgNy45NDczMiAxNC40ODc3TDQuNDI2NDMgMTEuMjE4NUM0LjA5MjIyIDEwLjkwODIgNC4wNzc3OCAxMC4zOTA5IDQuMzk0MiAxMC4wNjMxQzQuNzEwNjEgOS43MzUzMSA1LjIzODA1IDkuNzIxMTYgNS41NzIyNyAxMC4wMzE1TDguNDI2MTUgMTIuNjgxNEwxNC4zNDE0IDUuMjE5NDlDMTQuNjIzOSA0Ljg2MzE0IDE1LjE0NzUgNC43OTg4NiAxNS41MTA4IDUuMDc1OTJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center no-repeat;
  opacity: 1;
  transition: opacity 0.3s;
}

.price-filter {
  display: flex;
  align-items: center; /* Вертикальное выравнивание по центру */
  justify-content: space-between; /* Равномерное распределение между элементами */
  gap: 10px; /* Отступы между элементами */
  margin-top: 10px;
}

.price-filter input {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #2c243e;
  color: white;
  border-radius: 5px;
  width: 80px;
  text-align: center;
  font-size: 16px;
  -moz-appearance: textfield; /* Firefox */
}
.price-input {
  flex: 1;
  max-width: 80px; /* Ограничиваем максимальную ширину полей ввода */
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #4e4761; /* Стилизация полей ввода */
  background-color: #2c243e;
  color: white;
}
.price-input::-webkit-outer-spin-button,
.price-input::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
  margin: 0; /* Убираем отступы */
}
.price-filter input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.price-filter span {
  color: white;
  font-size: 18px;
}

button {
  background-color: #ff1493;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap; /* Предотвращаем перенос текста на новую строку */
}

button:hover {
  background-color: #e01170;
}


input[type="number"] {
  background: transparent;
    border: none;
    color: #a9abbd;
    max-width: 88%;
    outline: none;
    padding: 8px 0px;
    border: 1px solid rgba(216, 209, 234, .2);
}
.filter-arrow {
  transition: transform 0.3s ease; /* Плавная анимация поворота */
  transform: rotate(-90deg); /* Изначально поворачиваем на 90 градусов вправо */
}

.filter-arrow.open {
  transform: rotate(90deg); /* При открытии поворачиваем на 180 градусов */
}
.filter-clear {
  margin-top: 20px;
  text-align: center;
}

.filter-clear span {
  cursor: pointer;
  color: #ff1493;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease;
}

.filter-clear span:hover {
  color: #e01170;
}
.sort-options {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: relative; /* Для позиционирования стрелки */
}

.sort-select-wrapper {
  position: relative;
}

.sort-options label {
  margin-right: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.sort-options select {
  color: #ffffff;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ff1493;
  cursor: pointer;
  outline: none;
  appearance: none;
  width: 180px;
  margin-right: 30px;
}

.sort-options select:hover {
  border-color: #e01170;
}

.sort-options select:focus {
  border-color: #ff1493;
}

/* Стрелка */
.filter-arrow-sort {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg); /* Изначально повернута на 90 градусов */
  transition: transform 0.3s ease;
  width: 10px;
  height: 10px;
  background-image: url('../assets/srelka.svg');
  background-size: cover;
  margin-right: 30px;
}

.filter-arrow.open {
  transform: translateY(-50%) rotate(90deg); /* Поворот на 180 градусов при открытии */
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Высота, которую вы хотите для зоны загрузки */
}

.loading-gif {
  width: 150px; /* Настройте размер гифки */
  height: 150px;
}

/* Медиазапрос для мобильных устройств с шириной менее 768px */
@media (max-width: 768px) {

  .container {
    max-width: 100%; /* Убедитесь, что родительские элементы не превышают 100% ширины */
  }
  .mobile-filters-button:hover {
      background-color: #e01170; /* Темнее розовый при наведении */
      transform: translateY(-3px); /* Легкий подъем кнопки при наведении */
  }

  .mobile-filters-button:active {
      transform: translateY(0); /* Возврат кнопки на место при клике */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Уменьшаем тень при нажатии */
  }

  .filters {
    display: none;
  }

  .game-container {
    padding-left: 10px;  /* Уменьшаем отступы для мобильных устройств */
    padding-right: 10px;
    flex-direction: column;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    padding-bottom: 80px;
    max-height: none;
    padding-top: 10px;
  }

  .products-section {
    overflow-y: visible; /* Disable vertical scroll on mobile */
    max-height: none; /* Remove max-height limit */
    padding-right: 0; /* Remove padding on the right */
  }

  .product-game-title {
    font-size: 24px;  /* Уменьшаем размер заголовка */
    margin-bottom: 10px;
  }

  .sort-options {
    justify-content: center;  /* Центрируем элементы сортировки */
    margin-bottom: 10px;
  }

  .products-game-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Два продукта в строке */
    gap: 10px;
  }

  /* Уменьшаем размер контейнера изображения */
  .product-game-image-container {
    height: 100px;  /* Уменьшаем высоту изображения */
  }

  /* Изменяем размер изображения */
  .product-game-image {
    width: 100%;  /* Изображение занимает всю ширину контейнера */
    height: 100%;  /* Автоматическая высота для пропорционального отображения */
    object-fit: contain;  /* Сохраняем пропорции изображения */
  }

  .product-game-image {
    width: 100%;  /* Изображения на всю ширину контейнера */
    height: auto;
  }

  .product-game-cart {
    padding: 5px;  /* Меньше отступов */
  }

  .product-game-name-category {
    font-size: 14px; /* Уменьшенный текст для категорий */
  }

  .product-game-price {
    font-size: 16px; /* Уменьшаем шрифт для цены */
  }

  .filter-section {
    margin-bottom: 15px;
  }

  .filter-header {
    font-size: 16px;  /* Уменьшаем размер текста для заголовков фильтров */
  }

  .filter-checkboxes label {
    font-size: 14px;  /* Уменьшаем размер шрифта для чекбоксов */
  }

  .price-filter input {
    padding: 5px;  /* Уменьшаем отступы в полях ввода цены */
    font-size: 14px;  /* Уменьшаем размер текста */
  }

  .filters-and-sort {
    display: flex;
    justify-content: space-between; /* Выравнивание по краям */
    align-items: center; /* Выравнивание по вертикали */
    margin-bottom: 20px;
    gap: 10px; /* Добавление отступа между кнопкой и сортировкой */
  }
  
  .mobile-filters-button {
    padding: 5px 60px;
    background-color: #ff1493;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 44px;
    margin-top: -9px;
}
  
  .sort-options select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ff1493;
    cursor: pointer;
    height: 45px; /* Сделать высоту такой же, как у кнопки */
    display: flex;
    align-items: center; /* Центровка текста внутри */
  }
  

  .filter-clear {
    font-size: 14px;  /* Уменьшаем размер кнопки очистки фильтров */
  }

  .buy-game-now-button {
    padding: 5px 10px;  /* Уменьшаем отступы для кнопок "Купить сейчас" */
  }

  .view-cart-button {
    display: none; /* Кнопка занимает всю ширину экрана */
  }

  .filters {
    padding: 15px;  /* Уменьшаем внутренние отступы */
    width: 100%;  /* Фильтры на всю ширину */
    margin-bottom: 20px;  /* Увеличиваем отступ внизу */
  }

  .cart-container {
    padding: 20px;
  }

  .checkout-button {
    padding: 10px 20px;  /* Уменьшаем отступы кнопки оформления заказа */
  }
}
