/* Общие стили для Home */
.home {
  padding-top: 80px;
  font-family: "Manrope",sans-serif;
  background: linear-gradient(135deg, #0c0e27, #3a3136);
  min-height: 100vh;
  transition: margin-left 0.3s ease;
}
html, body {
  overflow-x: hidden;
}

.shift-right {
  margin-left: 280px;
}
.product-title {
  padding-left:10px;

  font-size: 36px;
  margin-bottom: 20px;
  color: #ff1493;
}

.first-section {
  /* Флекс-контейнер, в котором три блока: левая картинка, центр, правая картинка */
  display: flex;
  align-items: center;
  justify-content: space-around; /* Чтобы было пространство между блоками */
  padding: 40px 0;
  max-width: 1330px; /* При желании ограничить ширину секции */
  margin: 0 auto; /* Центрировать секцию по горизонтали */
}

/* Левая и правая колонки (с картинками) */
.side-image {
  width: 335px; /* Зафиксированная ширина, подкорректируйте под нужный размер */
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* Картинки внутри боковых колонок */
.side-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}

/* Центральный блок с текстом */
.center-content {
  flex: 1;               /* Занимает всё оставшееся место */
  text-align: center;    /* Центрируем текст */
  margin: 0 20px;        /* Отступы слева и справа, чтобы текст не лип к картинкам */
  box-sizing: border-box;
}


.section-content {
  max-width: 50%;
  margin-top: -110px;
}

.section-title {
  /* Вместо обычного color делаем заливку фоном-градиентом */
  font-size: 64px;
  margin-bottom: 20px;
  line-height: 1.2;
  background: linear-gradient(99.93deg, #FF1493 26.5%, #BB00FF 81.83%);
  -webkit-background-clip: text;       /* Для Safari/Chrome */
  -webkit-text-fill-color: transparent;/* Делаем текст прозрачным, виден только градиент */
  background-clip: text;              /* Для современных браузеров */
  color: transparent;                 /* Подстраховка */
}

/* Подзаголовок */
.section-subtitle {
  color: #7E7E7E;
  font-size: 24px;
  margin-bottom: 30px;
}

.buttons-container {
  display: flex;
  gap: 20px;
  justify-content: space-around;
}

.primary-button {
  background: linear-gradient(99.93deg, #FF1493 26.5%, #BB00FF 81.83%);
  color: white;
  font-weight: 600;
  padding: 14px 62px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: rgba(31, 31, 46, 0.8);
}

.secondary-button {
  background-color: rgba(31, 31, 46, 0.8);
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid #e91db6;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.secondary-button:hover {
  background-color: #e91db6;
  color: white;
}

.image-overlay-container {
  position: relative;
  width: 800px;
  height: auto;
  left:40px
}

.foreground-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  position: static; /* или вовсе убрать position */
}


.background-image {
  width: 600px;
  height: auto;
  margin-top: -80px;
  position: relative;
  left: 240px;
}

.second-section {
  padding: 4px 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.stat-card {
  background: rgba(31, 31, 46, 0.2);
  padding-left: 15px;
  border-radius: 10px;
  text-align: left;
  color: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 2px solid #e91db6;
  height: 200px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.stat-card:hover {
  box-shadow: 0 8px 16px rgba(224, 54, 216, 0.7);
  transform: translateY(-10px);
}

.stat-number {
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 10px;
}

.stat-divider {
  border: none;
  border-top: 1px solid #e91db6;
  margin: 10px 0;
}

.stat-description {
  font-size: 16px;
  color: #d0d0d8;
}

.products-home-section {
  margin-bottom: 40px;
}

.products-home-section .products-wrapper {
  overflow: hidden; /* скрываем лишнее */
  width: 100%;
}
/* Меняем сетку товаров на flex и добавляем анимацию */
.products-grid {
  display: flex;
  flex-wrap: nowrap; /* предотвращает перенос */
  gap: 20px;
  padding-right: 10px;
  position: relative;
  /* Задаём анимацию для непрерывного скролла */
  animation: scrollItems 30s linear infinite;
}

@keyframes scrollItems {
  0% {
    transform: translateX(0);
  }
  100% {
    /* Смещаем на ширину ряда товаров. 
       Если карточек 5 и между ними gap 20px, то общее смещение можно подобрать экспериментально.
       Например, если контент дублирован, смещение должно быть равно общей ширине одного набора. */
    transform: translateX(-100%);
  }
}


.product-cart {
  flex: 0 0 calc((100% - 4 * 20px) / 5); /* 5 карточек + 4 промежутка по 20px */
  /* Остальные свойства остаются без изменений */
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.product-cart:hover .product-cart_inside {
  transform: scale(1.05); /* Увеличение элемента внутри карточки при наведении */
  box-shadow: 0 8px 16px; /* Стандартная подсветка */
}

/* Эффекты для "inside" зависят от раритетности */
.product-cart[data-rarity="Covert"] .product-cart_inside:hover {
  box-shadow: 0 8px 16px rgba(238, 44, 44, 0.7); /* Подсветка для Covert */
}

.product-cart[data-rarity="Classified"] .product-cart_inside:hover {
  box-shadow: 0 8px 16px rgba(178, 97, 252, 0.7); /* Подсветка для Classified */
}

.product-cart[data-rarity="Restricted"] .product-cart_inside:hover {
  box-shadow: 0 8px 16px rgba(253, 51, 199, 0.7); /* Подсветка для Restricted */
}

.product-cart[data-rarity="Knife"] .product-cart_inside:hover {
  box-shadow: 0 8px 16px rgba(255, 224, 51, 0.7); /* Подсветка для Knife */
}

.product-cart[data-rarity="Mil-Spec Grade"] .product-cart_inside:hover {
  box-shadow: 0 8px 16px rgba(71, 108, 255, 0.7); /* Подсветка для Mil-Spec Grade */
}

.product-cart[data-rarity="Industrial Grade"] .product-cart_inside:hover {
  box-shadow: 0 8px 16px rgba(51, 194, 250, 0.7); /* Подсветка для Industrial Grade */
}

.product-cart[data-rarity="Consumer Grade"] .product-cart_inside:hover {
  box-shadow: 0 8px 16px rgba(31, 31, 46, 0.7); /* Подсветка для Consumer Grade */
}

.product-cart_inside {
  position: relative;
  z-index: 1;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-cart_inside::before {
  content: "";
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
  filter: blur(50px);
  opacity: 0.6;
}

/* Динамическое изменение бордюра и цвета для inside */
.product-cart[data-rarity="Covert"] .product-cart_inside {
  border-color: #ee2c2c;
}
.product-cart[data-rarity="Covert"] .product-cart_inside::before {
  background-color: #ee2c2c;
}
.product-cart[data-rarity="Covert"] .product-name {
  color: #ee2c2c; /* Цвет заголовка для Covert */
}

.product-cart[data-rarity="Classified"] .product-cart_inside {
  border-color: #b261fc;
}
.product-cart[data-rarity="Classified"] .product-cart_inside::before {
  background-color: #b261fc;
}
.product-cart[data-rarity="Classified"] .product-name {
  color: #b261fc; /* Цвет заголовка для Classified */
}

.product-cart[data-rarity="Restricted"] .product-cart_inside {
  border-color: #fd33c7;
}
.product-cart[data-rarity="Restricted"] .product-cart_inside::before {
  background-color: #fd33c7;
}
.product-cart[data-rarity="Restricted"] .product-name {
  color: #fd33c7; /* Цвет заголовка для Restricted */
}

.product-cart[data-rarity="Knife"] .product-cart_inside {
  border-color: rgb(255, 224, 51);
}
.product-cart[data-rarity="Knife"] .product-cart_inside::before {
  background-color: rgb(255, 224, 51);
}
.product-cart[data-rarity="Knife"] .product-name {
  color: rgb(255, 224, 51); /* Цвет заголовка для Knife */
}

.product-cart[data-rarity="Mil-Spec Grade"] .product-cart_inside {
  border-color: #476cff;
}
.product-cart[data-rarity="Mil-Spec Grade"] .product-cart_inside::before {
  background-color: #476cff;
}
.product-cart[data-rarity="Mil-Spec Grade"] .product-name {
  color: #476cff; /* Цвет заголовка для Mil-Spec Grade */
}

.product-cart[data-rarity="Industrial Grade"] .product-cart_inside {
  border-color: #33c2fa;
}
.product-cart[data-rarity="Industrial Grade"] .product-cart_inside::before {
  background-color: #33c2fa;
}
.product-cart[data-rarity="Industrial Grade"] .product-name {
  color: #33c2fa; /* Цвет заголовка для Industrial Grade */
}

.product-cart[data-rarity="Consumer Grade"] .product-cart_inside {
  border-color: #7a879a;
}
.product-cart[data-rarity="Consumer Grade"] .product-cart_inside::before {
  background-color: #7a879a;
}
.product-cart[data-rarity="Consumer Grade"] .product-name {
  color: #babdc2; /* Цвет заголовка для Consumer Grade */
}

/* Остальные стили */
.product-image-container {
  width: 100%;
  height: 180px; /* фиксированная высота */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* чтобы изображение не выходило за пределы */
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* или object-fit: contain, если нужно вписать картинку полностью */
}


.product-info {
  text-align: left;
}

.product-name-category {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-price-quality {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 7px;
}

.product-price {
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
}

.product-quality-tag {
  background-color: #28a745;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 5px;
}

.product-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy-now-button {
  background-color: #262626;
  color: #ffffff;
  padding: 2px 2px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.buy-now-button img {
  width: 20px;
  height: auto;
}

.buy-now-button:hover {
  background-color: #444444;
}

.see-more-overlay {
  position: absolute;
  top: 0;
  right: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.6), transparent);
  z-index: 2;
}


.see-more-button {
  background-color: #ff1493;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.see-more-button:hover {
  background-color: #e01170;
}
.store-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border-radius: 15px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.store-image-container {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-image {
  width: auto;
  height: auto;
  border-radius: 50%;
}

.store-info-container {
  width: 50%;
  padding-left: 20px;
}

.store-title {
  font-size: 48px;
  color: #ff1493;
  margin-bottom: 20px;
}

.store-description {
  font-size: 18px;
  color: #e1e2e5;
  margin-bottom: 20px;
}

.store-features {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.store-features li {
  font-size: 18px;
  color: #e1e2e5;
  margin-bottom: 10px;
  position: relative;
}

.store-features li::before {
  position: absolute;
  left: -30px;
  color: #ff1493;
}

.store-button {
  background-color: #ff1493;
  color: #e1e2e5;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.store-button:hover {
  background-color: #e01170;
}
.faq-section {
  border-radius: 15px;
  margin-top: 40px;
}

.faq-title {
  font-size: 48px;
  color: #ff1493;
  text-align: center;
  margin-bottom: 30px;
}

.faq-container {
  max-width: 900px; /* Увеличил размер контейнера */
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 30px; /* Увеличен отступ между элементами */
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.05);
  padding: 20px; /* Увеличен padding */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.1); /* Создает выпуклость */
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-5px); /* Эффект подъема при наведении */
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.3), 0 6px 12px rgba(0, 0, 0, 0.2);
}

.faq-question {
  width: 100%;
  padding: 20px;
  background: none;
  color: #ffffff;
  font-size: 22px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.faq-question:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.faq-toggle {
  font-size: 24px;
  color: #fcfbfb;
  background: linear-gradient(145deg, #ff3399, #cc0077); /* Создает эффект выпуклости */
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -4px -4px 8px rgba(255, 255, 255, 0.1); /* Выпуклый эффект */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.faq-toggle:hover {
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.15);
  transform: scale(1.1);
}

.faq-answer {
  padding: 20px;
  color: #ffffff;
  font-size: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.faq-see-more-button {
  background-color: #f8f8f8;
  color: white;
  padding: 12px 24px; /* Увеличен padding */
  border: none;
  border-radius: 10px; /* Сделано более округлым */
  font-size: 18px; /* Увеличен размер текста */
  cursor: pointer;
  margin-top: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1); /* Выпуклость для кнопки */
}

.faq-see-more-button:hover {
  background-color: #fafafa;
  box-shadow: 0 6px 12px rgba(255, 20, 147, 0.4), 0 4px 8px rgba(0, 0, 0, 0.2); /* Подсветка на hover */
}
/* Ключевые кадры анимации пиксельного проявления */
@keyframes funnelIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.side-image img.foreground-image {
  animation: funnelIn 1s ease-out forwards;
}


@media (max-width: 1080px) {
  .side-image {
    display: none;
  }

  .home {
    padding-right: 20px;
    padding-top: 18px;
    padding-left: 5%;
  }

  .first-section {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
  }

  .section-content {
    max-width: 100%;
    text-align: left;
    gap: 20px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
  }

  .section-title {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .section-subtitle {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 400;
  }

  .buttons-container {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 25px;
  }

  .primary-button, .secondary-button {
    font-size: 18px;
    padding: 15px 20px;
  }

  .image-overlay-container {
    order: 3;
    width: 100%;
    max-width: 500px;
    margin-top: 25px;
  }

  .foreground-image {
    width: 100%;
    height: auto;
    left: 5%;
  }

  .background-image {
    width: 100%;
    height: auto;
    position: absolute;
    left: 5%;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .stats-grid {
    grid-template-columns: repeat(3, 1fr); /* Сетка в 3 колонки */
    gap: 20px;
  }

  .stat-card {
    padding: 20px;
    text-align: center;
    border-radius: 12px;
    height: auto;
  }

  .stat-number {
    font-size: 34px;
  }

  .stat-description {
    font-size: 14px;
  }

  .product-title {
    font-size: 32px;
  }

  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    padding-right: 10px;
    animation: none; /* отключаем анимацию бегущей строки */
  }

  .product-cart {
    padding: 10px;
  }

  .product-image-container {
    height: 180px;
  }

  .product-name {
    font-size: 20px;
  }

  .product-price-quality {
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: flex-start;
  }

  .product-price {
    font-size: 22px;
  }

  .product-quality-tag {
    font-size: 12px;
  }

  .buy-now-button {
    padding: 10px;
  }

  .store-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 30px;
  }

  .store-image-container {
    width: 100%;
    margin-bottom: 30px;
  }

  .store-image {
    width: 400px;
    height: 400px;
    border-radius: 50%;
  }

  .store-info-container {
    width: 100%;
    text-align: left;
    padding-left: 0;
  }

  .store-title {
    font-size: 36px;
    margin-bottom: 15px;
  }

  .store-description {
    font-size: 18px;
    margin-bottom: 20px;
    color: #e1e2e5;
  }

  .store-features {
    font-size: 18px;
  }

  .store-features li {
    margin-bottom: 12px;
  }

  .store-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
  }

  .faq-section {
    margin-top: 30px;
    padding: 0 15px;
  }

  .faq-title {
    font-size: 36px;
    margin-bottom: 25px;
  }

  .faq-container {
    max-width: 100%;
    padding: 0 20px;
  }

  .faq-item {
    margin-bottom: 25px;
    padding: 20px;
    border-radius: 12px;
  }

  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    font-size: 20px;
    height: auto;
    min-height: 80px;
    text-align: start;
  }

  .faq-toggle {
    font-size: 24px;
    width: 35px;
    height: 35px;
  }

  .faq-answer {
    padding: 20px;
    font-size: 18px;
  }

  .faq-see-more-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    margin-top: 25px;
  }
}

/* Медиазапрос для устройств шириной до 768px */
@media (max-width: 768px) {
  .side-image {
    display: none;
  }

  .home {
    padding-top: 18px;
  }

  .first-section {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
  }
  .section-content { 
    max-width: 100%;
    text-align: left;
    gap: 16px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .section-title {
    font-size: 28px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .section-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .buttons-container {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .primary-button, .secondary-button {
    font-size: 16px;
    padding: 12px 15px;
  }

  .image-overlay-container {
    order: 3; /* Изображения идут после кнопок */
    width: 100%;
    max-width: 400px; /* Ограничиваем максимальную ширину изображения */
    margin-top: 20px;
  }

  .foreground-image{
    width: 100%;
    height: auto;
    left:7%;
  }
  
  .background-image {
    width: 100%;
    height: auto;
    position: absolute; /* Позиционируем относительно родителя */
    left: 7%; /* Смещаем от левого края */
    z-index: 0; /* Отправляем элемент на задний план */
    top: 0; /* Устанавливаем верхнюю позицию */
    right: 0; /* Определяем ширину от правого края */
    bottom: 0; /* Покрываем высоту контейнера */
  } 

  .stats-grid {
    grid-template-columns: repeat(2, 1fr); /* Меняем сетку на 2 колонки */
    gap: 15px;
  }

  .stat-card {
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    height: auto;
  }

  .stat-number {
    font-size: 28px; /* Еще меньшее значение для чисел */
  }

  .stat-description {
    font-size: 12px; /* Еще меньшее описание */
  }
  .product-title {
    font-size: 25px;
  }
  .products-grid {
    grid-template-columns: repeat(2, 1fr); /* Два элемента в ряд для мобильных */
    gap: 5px;
  }

  .product-cart {
    padding: 2px;
  }
  .product-image {
    width: 80%;
    height: auto;
    object-fit: contain;
  }
  .product-image-container {
    height: 140px; /* Уменьшение высоты для мобильных */
  }

  .product-name {
    font-size: 16px; /* Уменьшение шрифта для названия продукта */
  }

  .product-price-quality {
    display: flex;
    flex-direction: column;
    padding: 2px;
    align-items: flex-start; /* Выровнять по левой стороне */
  }


  .product-price {
    font-size: 18px; /* Уменьшение шрифта для цены */
  }

  .product-quality-tag {
    font-size: 10px; /* Уменьшение шрифта для тега качества */
  }

  .buy-now-button {
    padding: 5px;
  }

  .see-more-overlay {
    display: none; /* Скрытие наложения "see more" на мобильных */
  }
  .store-section {
    display: flex;
    flex-direction: column; /* Элементы выстраиваются вертикально */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
  }

  .store-image-container {
    width: 100%; /* Ширина контейнера для изображения на 100% */
    margin-bottom: 20px; /* Отступ между изображением и текстом */
  }

  .store-image {
    width: 300px;
    height: 300px;;
    border-radius: 50%;
  }

  .store-info-container {
    width: 100%; /* Текстовый блок на всю ширину */
    text-align: left; /* Выравнивание по центру */
    padding-left: 0; /* Убираем отступы */
  }

  .store-title {
    font-size: 32px; /* Уменьшаем размер заголовка */
    margin-bottom: 10px;
  }

  .store-description {
    font-size: 16px; /* Уменьшаем размер текста */
    margin-bottom: 15px;
    color: #e1e2e5;
  }

  .store-features {
    font-size: 16px; /* Уменьшаем размер списка характеристик */
  }

  .store-features li {
    margin-bottom: 8px; /* Уменьшение отступов между элементами списка */
    font-size: 16px;
  }

  .store-button {
    width: 100%; /* Кнопка занимает всю ширину экрана */
    padding: 12px;
    font-size: 16px; /* Уменьшение размера текста на кнопке */
  }
  .faq-section {
    margin-top: 20px;
    padding: 0 10px; /* Добавим отступы с боков */
  }

  .faq-title {
    font-size: 32px; /* Уменьшаем размер заголовка */
    margin-bottom: 20px;
  }

  .faq-container {
    max-width: 100%; /* Делаем контейнер на всю ширину */
    padding: 0 10px; /* Отступы для удобства на маленьких экранах */
  }

  .faq-item {
    margin-bottom: 20px; /* Уменьшаем отступ между элементами */
    padding: 15px; /* Уменьшаем padding */
    border-radius: 10px; /* Уменьшаем радиус закругления */
  }

  .faq-question {
      display: flex;
      justify-content: space-between;
      align-items: center; /* Выравнивание кнопки по центру */
      padding: 20px;
      background: none;
      color: #ffffff;
      font-size: 18px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      white-space: normal; /* Разрешаем перенос текста */
      word-wrap: break-word; /* Переносим длинные слова */
      height: auto; /* Автоматическая высота */
      min-height: 80px; /* Минимальная высота для всех элементов */
      text-align: start;
  }

  .faq-toggle {
      font-size: 20px; /* Размер значка */
      width: 30px; /* Фиксированная ширина */
      height: 30px; /* Фиксированная высота */
      display: flex;
      justify-content: center; /* Центрирование содержимого */
      align-items: center; /* Центрирование содержимого */
      background: linear-gradient(145deg, #ff3399, #cc0077); /* Градиент */
      border-radius: 50%; /* Круглая кнопка */
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -4px -4px 8px rgba(255, 255, 255, 0.1); /* Выпуклый эффект */
      transition: box-shadow 0.3s ease, transform 0.3s ease;
      flex-shrink: 0; /* Предотвращаем сжатие кнопки */
  }

  .faq-toggle:hover {
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.15);
      transform: scale(1.1);
  }

  .faq-answer {
    padding: 15px; /* Уменьшаем padding для ответа */
    font-size: 16px; /* Уменьшаем размер текста ответа */
  }

  .faq-see-more-button {
    width: 100%; /* Кнопка на всю ширину */
    padding: 10px; /* Уменьшаем padding кнопки */
    font-size: 16px; /* Уменьшаем размер текста на кнопке */
    margin-top: 20px; /* Уменьшаем отступ сверху */
  }
}
@media (max-width: 480px) {
  .side-image {
    display: none;
  }

  .home {
    padding-top: 18px;
  }

  .first-section {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
  }
  .section-content { 
    max-width: 100%;
    text-align: left;
    gap: 16px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .section-title {
    font-size: 28px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .section-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .buttons-container {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .primary-button, .secondary-button {
    font-size: 16px;
    padding: 12px 15px;
  }

  .image-overlay-container {
    order: 3; /* Изображения идут после кнопок */
    width: 100%;
    max-width: 400px; /* Ограничиваем максимальную ширину изображения */
    margin-top: 20px;
  }

  .foreground-image{
    width: 100%;
    height: auto;
    left:7%;
  }
  
  .background-image {
    width: 100%;
    height: auto;
    position: absolute; /* Позиционируем относительно родителя */
    left: 7%; /* Смещаем от левого края */
    z-index: 0; /* Отправляем элемент на задний план */
    top: 0; /* Устанавливаем верхнюю позицию */
    right: 0; /* Определяем ширину от правого края */
    bottom: 0; /* Покрываем высоту контейнера */
  } 

  .stats-grid {
    grid-template-columns: repeat(2, 1fr); /* Меняем сетку на 2 колонки */
    gap: 15px;
  }

  .stat-card {
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    height: auto;
  }

  .stat-number {
    font-size: 28px; /* Еще меньшее значение для чисел */
  }

  .stat-description {
    font-size: 12px; /* Еще меньшее описание */
  }
  .product-title {
    font-size: 25px;
  }
  .products-grid {
    grid-template-columns: repeat(2, 1fr); /* Два элемента в ряд для мобильных */
    gap: 5px;
  }

  .product-cart {
    padding: 2px;
  }

  .product-image-container {
    height: 140px; /* Уменьшение высоты для мобильных */
  }

  .product-name {
    font-size: 16px; /* Уменьшение шрифта для названия продукта */
  }

  .product-price-quality {
    display: flex;
    flex-direction: column;
    padding: 2px;
    align-items: flex-start; /* Выровнять по левой стороне */
  }


  .product-price {
    font-size: 18px; /* Уменьшение шрифта для цены */
  }

  .product-quality-tag {
    font-size: 10px; /* Уменьшение шрифта для тега качества */
  }

  .buy-now-button {
    padding: 5px;
  }

  .see-more-overlay {
    display: none; /* Скрытие наложения "see more" на мобильных */
  }
  .store-section {
    display: flex;
    flex-direction: column; /* Элементы выстраиваются вертикально */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
  }

  .store-image-container {
    width: 100%; /* Ширина контейнера для изображения на 100% */
    margin-bottom: 20px; /* Отступ между изображением и текстом */
  }

  .store-image {
    width: 300px;
    height: 300px;;
    border-radius: 50%;
  }

  .store-info-container {
    width: 100%; /* Текстовый блок на всю ширину */
    text-align: left; /* Выравнивание по центру */
    padding-left: 0; /* Убираем отступы */
  }

  .store-title {
    font-size: 32px; /* Уменьшаем размер заголовка */
    margin-bottom: 10px;
  }

  .store-description {
    font-size: 16px; /* Уменьшаем размер текста */
    margin-bottom: 15px;
    color: #e1e2e5;
  }

  .store-features {
    font-size: 16px; /* Уменьшаем размер списка характеристик */
  }

  .store-features li {
    margin-bottom: 8px; /* Уменьшение отступов между элементами списка */
    font-size: 16px;
  }

  .store-button {
    width: 100%; /* Кнопка занимает всю ширину экрана */
    padding: 12px;
    font-size: 16px; /* Уменьшение размера текста на кнопке */
  }
  .faq-section {
    margin-top: 20px;
    padding: 0 10px; /* Добавим отступы с боков */
  }

  .faq-title {
    font-size: 32px; /* Уменьшаем размер заголовка */
    margin-bottom: 20px;
  }

  .faq-container {
    max-width: 100%; /* Делаем контейнер на всю ширину */
    padding: 0 10px; /* Отступы для удобства на маленьких экранах */
  }

  .faq-item {
    margin-bottom: 20px; /* Уменьшаем отступ между элементами */
    padding: 15px; /* Уменьшаем padding */
    border-radius: 10px; /* Уменьшаем радиус закругления */
  }

  .faq-question {
      display: flex;
      justify-content: space-between;
      align-items: center; /* Выравнивание кнопки по центру */
      padding: 20px;
      background: none;
      color: #ffffff;
      font-size: 18px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      white-space: normal; /* Разрешаем перенос текста */
      word-wrap: break-word; /* Переносим длинные слова */
      height: auto; /* Автоматическая высота */
      min-height: 80px; /* Минимальная высота для всех элементов */
      text-align: start;
  }

  .faq-toggle {
      font-size: 20px; /* Размер значка */
      width: 30px; /* Фиксированная ширина */
      height: 30px; /* Фиксированная высота */
      display: flex;
      justify-content: center; /* Центрирование содержимого */
      align-items: center; /* Центрирование содержимого */
      background: linear-gradient(145deg, #ff3399, #cc0077); /* Градиент */
      border-radius: 50%; /* Круглая кнопка */
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -4px -4px 8px rgba(255, 255, 255, 0.1); /* Выпуклый эффект */
      transition: box-shadow 0.3s ease, transform 0.3s ease;
      flex-shrink: 0; /* Предотвращаем сжатие кнопки */
  }

  .faq-toggle:hover {
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.15);
      transform: scale(1.1);
  }

  .faq-answer {
    padding: 15px; /* Уменьшаем padding для ответа */
    font-size: 16px; /* Уменьшаем размер текста ответа */
  }

  .faq-see-more-button {
    width: 100%; /* Кнопка на всю ширину */
    padding: 10px; /* Уменьшаем padding кнопки */
    font-size: 16px; /* Уменьшаем размер текста на кнопке */
    margin-top: 20px; /* Уменьшаем отступ сверху */
  }
}
