.shop-container {
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 80px;
    padding-bottom: 100px;
    background: transparent;
    color: white;
    margin-left: 0;
    border-radius: 15px;
}
.checkout-button:disabled {
  background: #999;       /* Серый фон для disabled-состояния */
  cursor: not-allowed;     /* Курсор в виде "запрещено" */
  opacity: 0.6;            /* Немного сниженная прозрачность для визуального эффекта */
}
/* Контейнер для чекбокса */
.policy-checkbox {
  margin-top: 20px;
}

/* Выравнивание чекбокса и текста по центру */
.policy-label {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  color: white;
}

/* Дополнительный отступ между чекбоксом и текстом */
.policy-text {
  margin-left: 8px;
}

/* Стилизация ссылки: розовый цвет */
.policy-link {
  color: #ff1493;  /* розовый цвет */
  text-decoration: none;
}

/* При наведении можно добавить подчёркивание */
.policy-link:hover {
  text-decoration: underline;
}

.shift-right {
    margin-left: 280px;
}
.quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .quantity-controls button {
    background: #ff1493;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .quantity-controls button:hover {
    background: #ff66b2;
  }
  
  .quantity-controls span {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
  }
  
.shop-title {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #ff1493;
}

.shop-items {
    display: flex;
    flex-direction: column;
    gap: 50px; /* Пространство между предметами */
}

.shop-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    box-shadow: 0 8px 16px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shop-item:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px;
}
/* Подсветка для .shop-item:hover в зависимости от раритетности */
.rarity-consumer-grade:hover {
    box-shadow: 0 12px 24px #bfbace; /* Синий подсветка */
}

.rarity-industrial-grade:hover {
    box-shadow: 0 12px 24px #6a98d4; /* Зеленая подсветка */
}

.rarity-mil-spec-grade:hover {
    box-shadow: 0 12px 24px #536dee; /* Желтая подсветка */
}

.rarity-restricted:hover {
    box-shadow: 0 12px 24px #fd33c7; /* Фиолетовая подсветка */
}

.rarity-classified:hover {
    box-shadow: 0 12px 24px #9d7af7; /* Розовая подсветка */
}

.rarity-covert:hover {
    box-shadow: 0 12px 24px #ee2c2c; /* Оранжевая подсветка */
}

.rarity-Knives:hover {
    box-shadow: 0 12px 24px rgba(255, 215, 0, 0.7); /* Золотая подсветка */
}
.rarity-consumer-grade {
    box-shadow: 0 0 15px #bfbace;
}

.rarity-industrial-grade {
    box-shadow: 0 0 15px #6a98d4;
}

.rarity-mil-spec-grade {
    box-shadow: 0 0 15px #536dee;
}

.rarity-restricted {
    box-shadow: 0 0 15px #fd33c7;
}

.rarity-classified {
    box-shadow: 0 0 15px #9d7af7;
}

.rarity-covert {
    box-shadow: 0 0 15px #ee2c2c;
}

.rarity-Knives {
    box-shadow: 0 0 20px #ffd700;
}

/* Базовый стиль контейнера изображения */
.item-image-container {
    position: relative;
    max-width: 100px;
    margin-right: 16px;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: padding-box;
    overflow: hidden;
    z-index: 1;
}

.item-image-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    z-index: 0;
    margin: -1px;
}

/* Внутренняя часть с картинкой товара */
.item-image-inside {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 6px;
    width: 100px;
    height: 100px;
    z-index: 1;
    overflow: hidden;
}

.item-image-inside:before {
    content: "";
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    filter: blur(72px);
    opacity: 0.64;
    z-index: 0;
}

/* Стили для самого изображения */
.item-image {
    width: 80%;
    height: 80%;
    object-fit: contain;
    z-index: 2;
}

/* Подсветка в зависимости от раритетности */


.rarity-consumer-grade .item-image-container:before {
    background: linear-gradient(180deg, #bfbace, rgba(122, 135, 154, .1));
}
.rarity-consumer-grade .item-image-inside {
    background: linear-gradient(180deg, #2b0320, rgba(94, 157, 255, 0.1));
}
.rarity-consumer-grade .item-image-inside:before {
    background-color: #bfbace;
}


.rarity-industrial-grade .item-image-container:before {
    background: linear-gradient(180deg, #6a98d4, rgba(76, 175, 80, 0.1));
}
.rarity-industrial-grade .item-image-inside {
    background: linear-gradient(180deg, #2b0320, rgba(76, 175, 80, 0.1));
}
.rarity-industrial-grade .item-image-inside:before {
    background-color: #6a98d4;
}

.rarity-mil-spec-grade .item-image-container:before {
    background: linear-gradient(180deg, #536dee, rgba(212, 225, 87, 0.1));
}
.rarity-mil-spec-grade .item-image-inside {
    background: linear-gradient(180deg, #2b0320, rgba(212, 225, 87, 0.1));
}
.rarity-mil-spec-grade .item-image-inside:before {
    background-color: #536dee;
}


.rarity-restricted .item-image-container:before {
    background: linear-gradient(180deg, #fd33c7, rgba(160, 32, 240, 0.1));
}
.rarity-restricted .item-image-inside {
    background: linear-gradient(180deg, #2b0320, rgba(160, 32, 240, 0.1));
}
.rarity-restricted .item-image-inside:before {
    background-color: #fd33c7;
}

.rarity-classified .item-image-container:before {
    background: linear-gradient(180deg, #9d7af7, rgba(255, 105, 180, 0.1));
}
.rarity-classified .item-image-inside {
    background: linear-gradient(180deg, #2b0320, rgba(255, 105, 180, 0.1));
}
.rarity-classified .item-image-inside:before {
    background-color: #9d7af7;
}


.rarity-covert .item-image-container:before {
    background: linear-gradient(180deg, #ee2c2c, rgba(238, 44, 44, .1));
}
.rarity-covert .item-image-inside {
    background: linear-gradient(180deg, #631111, rgba(238, 44, 44, .1));
}
.rarity-covert .item-image-inside:before {
    background-color: #ee2c2c;
}


.rarity-Knives .item-image-container:before {
    background: linear-gradient(180deg, #ffd700, rgba(255, 215, 0, 0.1));
}
.rarity-Knives .item-image-inside {
    background: linear-gradient(180deg, #2b0320, rgba(255, 215, 0, 0.1));
}
.rarity-Knives .item-image-inside:before {
    background-color: #ffd700;
}
/* Consumer Grade (синий) */
.rarity-consumer-grade .item-image-container {
    border-color: #bfbace; /* Синий бортик */
}

.rarity-consumer-grade h3 {
    color: #bfbace; /* Синий текст описания */
}

.rarity-industrial-grade .item-image-container {
    border-color: #6a98d4; /* Зеленый бортик */
}

.rarity-industrial-grade h3 {
    color: #6a98d4; /* Зеленый текст описания */
}

.rarity-mil-spec-grade .item-image-container {
    border-color: #536dee; /* Желтый бортик */
}

.rarity-mil-spec-grade h3 {
    color: #536dee; /* Желтый текст описания */
}

.rarity-restricted .item-image-container {
    border-color: #fd33c7; /* Фиолетовый бортик */
}

.rarity-restricted h3 {
    color: #fd33c7; /* Фиолетовый текст описания */
}

.rarity-classified .item-image-container {
    border-color: #9d7af7; /* Розовый бортик */
}

.rarity-classified h3 {
    color: #9d7af7; /* Розовый текст описания */
}

.rarity-covert .item-image-container {
    border-color: #ee2c2c; /* Оранжевый бортик */
}

.rarity-covert h3 {
    color: #ee2c2c; /* Оранжевый текст описания */
}

.rarity-Knives .item-image-container {
    border-color: #ffd700; /* Золотой бортик */
}

.rarity-Knives h3 {
    color: #ffd700; /* Золотой текст описания */
}
.item-details {
    flex-grow: 1;
}

.item-details p {
    margin: 5px 0;
}

.item-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
}
.remove-quantity-controls{
    gap: 5px;
    flex-direction: column; /* Уменьшаем отступы между кнопками количества */
}
.item-quality-tag {
    background-color: #28a745;
    color: white;
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 0.8rem;
}

.remove-item-button {
    background: #ff1493;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    width: 100%;

}

.order-summary {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 0px solid #ffffff;
}

.order-total {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: bold;
}

.checkout-button {
    background: #ff1493;
    color: white;
    border: none;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 8px;
    margin-top: 20px;
    transition: background 0.3s ease;
    width: 100%;
    text-align: center;
}

.checkout-button:hover {
    background: #ff66b2;
}

.empty-cart {
    font-size: 1.5rem;
    color: white;
    text-align: center;
    margin-top: 50px;
}
@media (max-width: 768px) {

    /* Контейнер магазина */
    .shop-container {
      padding: 20px 10px 60px 20px; /* Уменьшаем отступы */
      max-width: 100%; /* На всю ширину */
      border-radius: 10px;
      margin: 0 auto;
      padding-top: 80px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 100px;
    }
  
    /* Контейнер карточек */
    .shop-items {
      gap: 20px; /* Уменьшаем пространство между элементами */
    }
  
    /* Контейнер товара */
    .shop-item {
      flex-direction: column; /* Располагаем элементы вертикально */
      align-items: stretch; /* Центрируем элементы по горизонтали */
      gap: 10px; /* Пространство между элементами */
    }
  
    .item-image-container {
      max-width: 80px; /* Уменьшаем размер изображения */
      margin: 0 auto; /* Центрируем изображение */
    }
  
    .item-image-inside {
      width: 80px;
      height: 80px; /* Соответствующий размер контейнера для изображения */
    }
  
    .quantity-controls {/* Располагаем элементы управления количеством вертикально */
      gap: 15px;
    }
  
    .shop-title {
      font-size: 1.5rem; /* Уменьшаем размер заголовка */
      text-align: left; /* Центрируем заголовок */
    }
  
    .item-price {
      font-size: 1.2rem; /* Уменьшаем размер цены */
    }
  
    .item-quality-tag {
      font-size: 0.7rem; /* Уменьшаем размер тега качества */
      padding: 4px 8px; /* Уменьшаем отступы внутри тега */
    }
  
    .checkout-button {
      font-size: 1rem; /* Уменьшаем размер шрифта */
      padding: 10px 20px; /* Уменьшаем отступы */
    }
  
    /* Уменьшаем размер изображения для мобильных */
    .item-image {
      width: 70%; /* Уменьшаем размер изображения */
      height: auto;
    }
  
    /* Удаление боковых отступов */
    .game-container, .shop-container {
      overflow-x: hidden; /* Убираем горизонтальную прокрутку */
    }
  
    /* Рамки и тени уменьшаем на мобильных устройствах */
    .shop-item {
      padding: 15px; /* Уменьшаем отступы внутри товаров */
      box-shadow: 0 6px 12px; /* Уменьшаем тень */
    }
  
    /* Адаптация кнопок */
    .quantity-controls button {
      font-size: 14px; /* Уменьшаем размер кнопок */
      padding: 5px 8px;
    }
  
    .remove-quantity-controls {
      flex-direction: column;
    }
  
    .order-summary {
      margin-top: 20px; /* Уменьшаем отступ сверху */
    }
  
    /* Пример изменения расположения двух элементов в ряду */
    .shop-items {
      display: grid;
      grid-template-columns: 1fr 1fr; /* Две колонки для мобильной версии */
      gap: 20px;
    }
  

  }
  
